import { AFASApplication } from '@afas/podium/podium';

import { PodiumJsonApplicationConfig } from '../podium-json-application';

export function ifConfigMiddleware<R extends AFASApplication | PodiumJsonApplicationConfig>(
  middleware: (config: PodiumJsonApplicationConfig) => Promise<R>
): (appOrConfig: AFASApplication | PodiumJsonApplicationConfig) => Promise<R> {
  return async (appOrConfig): Promise<R> => {
    if (isConfig(appOrConfig)) {
      return middleware(appOrConfig);
    }

    return appOrConfig as R;
  };
}

function isConfig(appOrConfig: AFASApplication | PodiumJsonApplicationConfig): appOrConfig is PodiumJsonApplicationConfig {
  return 'appAssetsBaseUrl' in appOrConfig;
}
