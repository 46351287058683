import { METRIC_APP_INIT } from '@afas/blueprint/services/metric-constants';
import { CancelPromiseError } from '@afas/common/error/cancel-promise-error';
import type { AFASApplication } from '@afas/podium/podium/podium-application';

import type { PodiumJsonApplicationConfig } from './podium-json-application';
import { configMiddleware } from './middleware/config-middleware';
import { ifConfigMiddleware } from './middleware/if-config-middleware';

export function afasStart(window: Window): Promise<AFASApplication> {
  let scriptTag = window.document.querySelector('script[data-config-url]')!;
  let configUrl = scriptTag.getAttribute('data-config-url');

  return configMiddleware(window, configUrl)
    .then(config => import('./middleware/authentication-middleware').then(m => m.authenticationMiddleware(window, config)))
    .then(config => import('./middleware/scope-middleware').then(m => m.scopeMiddleware(window, config)))
    .then(ifConfigMiddleware(startAfasApplication))
    .then(app => {
      app.start();
      return app;
    });

  function startAfasApplication(config: PodiumJsonApplicationConfig) {
    return import(/* webpackChunkName: "afas-podium-chunk" */ '../podium-json-index').then(m => m.createAfasApplication(window, config));
  }
}

/* istanbul ignore if: auto start when in browser context */
if (typeof window !== 'undefined') {
  performance.mark(METRIC_APP_INIT);

  afasStart(window)
    .then(app => {
      (window as any).profitApp = (window as any).afasApp = app;
    })
    .catch(err => {
      if (err instanceof CancelPromiseError) {
        // All is well, the promise chain was canceled.
        return;
      }

      throw err;
    });
}
